import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { LoadingIndicator, } from "../../Common";
import moment from 'moment';
import { getRentContentNotification } from '../../../actions';
import { Wrapper } from './styled';
import Banner from "./Banner";
import { Link } from 'react-router-dom';
import { localStorageUtility } from '../../../utilities';

class NoticeDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersearch: [],
            inviteeIndex: -1,
            user: localStorageUtility('getAndParse', 'myData'),
            SignUpStatus: true,
            CancelStatus: true,
            id: this.props.match && this.props.match.params && this.props.match.params.id,
        }
    }

    componentDidMount(props) {
        this.props.getRentContentNotification(this.state.id);
    }

    render() {
        const { data } = this.props;
        const noticeContent = data.noticeContent;
        const orderData = noticeContent && noticeContent.order;
        const paymentData = noticeContent && noticeContent.payment;
        const shipmentData = noticeContent && noticeContent.shipment;
        return (
            <Wrapper key={0}>
                <LoadingIndicator shouldOpen={this.props.loading} />
                <Banner data={data.images} />
                <div id='NoticeDetail_content'>
                    <div className="title">
                        <h2>
                            主旨:
                            {/*蓮心專案申請通知*/}
                            {(noticeContent ? noticeContent.type : '') === 'order_success' && (
                                <span>
                                    蓮心專案申請通知
                                </span>
                            )}

                            {/*蓮心專案付款成功通知*/}
                            {(noticeContent ? noticeContent.type : '') === 'payment_success' && (
                                <span>
                                    蓮心專案付款成功通知
                                </span>
                            )}

                            {/*蓮心專案付款失敗通知*/}
                            {(noticeContent ? noticeContent.type : '') === 'payment_failed' && (
                                <span>
                                    蓮心專案付款失敗通知
                                </span>
                            )}

                            {/*蓮心專案申請換拍核定通知*/}
                            {(noticeContent ? noticeContent.type : '') === 'replace' && (
                                <span>
                                    蓮心專案申請換拍通知
                                </span>
                            )}

                            {/*蓮心專案申請換拍核定通知*/}
                            {(noticeContent ? noticeContent.type : '') === 'replace_payment' && (
                                <span>
                                    蓮心專案換拍核定通知
                                </span>
                            )}

                            {/*蓮心專案申請換拍核定通知*/}
                            {(noticeContent ? noticeContent.type : '') === 'replace_failed' && (
                                <span>
                                    蓮心專案申請換拍核定失敗通知
                                </span>
                            )}

                            {/*蓮心專案取消專案核定通知*/}
                            {(noticeContent ? noticeContent.type : '') === 'cancel' && (
                                <span>
                                    蓮心專案取消專案核定通知
                                </span>
                            )}

                            {/*蓮心專案球拍出貨通知*/}
                            {(noticeContent ? noticeContent.type : '') === 'shipment' && (
                                <span>
                                    蓮心專案球拍出貨通知
                                </span>
                            )}
                        </h2>
                    </div>
                    <ul>
                        <li>內容：</li>
                        <li>
                            <blockquote>
                                親愛的
                                {orderData ? orderData.user : '' || paymentData ? paymentData.user : '' || shipmentData ? shipmentData.user : ''}
                            </blockquote>
                        </li>
                        {/*蓮心專案申請通知*/}
                        {(noticeContent ? noticeContent.type : '') === 'order_success' && (
                            <blockquote>
                                <li>您已成功完成以下申請：</li>
                                <li>
                                    申請時間：
                                    {moment(orderData ? orderData.date : '').format('YYYY/MM/DD LT')}
                                </li>
                                <li>
                                    選配球拍：{orderData ? orderData.racket : ''}
                                </li>
                                <li>
                                    付款方式：
                                    {(() => {
                                        switch (orderData ? orderData.payment_type : '') {
                                            case "Y": return "年繳";
                                            case "A": return "一次付清";
                                            default: return "月繳";
                                        }
                                    })()}
                                </li>
                                <li className="padding-top-40px"></li>
                                <li>我們將會於您完成繳費動作後核定您所申請的專案，並再以告知您處理結果。</li>
                                <li>再次感謝您的使用。</li>
                                <li className="margin-top-20px margin-bottom-20px">
                                    <Link className="button" to={'/my_info/rent_contract_list'}>
                                        回到羽你蓮心
                                    </Link>
                                </li>
                                <li className="padding-bottom-40px"></li>
                            </blockquote>
                        )}
                        {/*蓮心專案付款成功通知*/}
                        {(noticeContent ? noticeContent.type : '') === 'payment_success' && (
                            <blockquote>
                                <li>
                                    您於
                                    {moment(paymentData ? paymentData.date : '').format('YYYY/MM/DD LT')}
                                    申請之蓮心專案已扣款成功，交易訊息如下:
                                </li>
                                <li>
                                    <span>
                                        專案起訖：
                                        {moment(paymentData ? paymentData.contract_start_time : '').format('YYYY/MM/DD')}
                                        -
                                        {moment(paymentData ? paymentData.contract_end_time : '').format('YYYY/MM/DD')}
                                    </span>
                                </li>
                                <li>選配球拍：{paymentData ? paymentData.racket : ''}</li>
                                <li>付款時間：{paymentData ? paymentData.payment_time : ''}</li>
                                <li>付款方式：{paymentData ? paymentData.trade_payment : ''}</li>
                                <li className="padding-top-40px"></li>
                                <li>再次感謝您的使用。</li>
                                <li className="margin-top-20px margin-bottom-20px">
                                    <Link className="button" to={'/my_info/rent_contract_list'}>
                                        回到羽你蓮心
                                    </Link>
                                </li>
                                <li className="padding-bottom-40px"></li>
                            </blockquote>
                        )}
                        {/*蓮心專案付款失敗通知*/}
                        {(noticeContent ? noticeContent.type : '') === 'payment_failed' && (
                            <blockquote>
                                <li>
                                    您於
                                    {moment(paymentData ? paymentData.date : '').format('YYYY/MM/DD LT')}
                                    雲羽集APP蓮心專案申請
                                </li>
                                <li>尚未付款成功</li>
                                <li>請點選下列網址執行重新付款</li>
                                <li className="margin-top-20px margin-bottom-20px">
                                    <Link className="button" to={'../rent_contract_detail/' + (paymentData ? paymentData.order_id : '')}>
                                        前往專案付款
                                    </Link>
                                </li>
                                <li></li>
                                <li className="padding-bottom-40px"></li>
                            </blockquote>
                        )}
                        {/*蓮心專案申請換拍通知*/}
                        {(noticeContent ? noticeContent.type : '') === 'replace' && (
                            <blockquote>
                                <li>
                                    您於
                                    {moment(orderData ? orderData.date : '').format('YYYY/MM/DD LT')}
                                    蓮心專案已申請換拍，訊息如下：
                                </li>
                                <li>
                                    申請編號：{orderData ? orderData.shipment_id : ''}
                                </li>
                                <li>
                                    專案起訖：
                                    {moment(orderData ? orderData.contract_start_time : '').format('YYYY/MM/DD')}
                                    -
                                    {moment(orderData ? orderData.contract_end_time : '').format('YYYY/MM/DD')}
                                </li>
                                <li>
                                    目前換拍次數：{orderData ? orderData.replace_times : ''}
                                </li>
                                <li className="padding-top-40px"></li>
                                <li>提醒您！此通知僅代表我們已收到您提出換拍申請，尚未核定成功！</li>
                                <li>我們將額外通知您核定結果。</li>
                                <li className="margin-top-20px margin-bottom-20px">
                                    <Link className="button" to={'/my_info/rent_contract_list'}>
                                        回到羽你蓮心
                                    </Link>
                                </li>
                                <li className="padding-bottom-40px"></li>
                            </blockquote>
                        )}
                        {/*蓮心專案申請換拍核定成功通知*/}
                        {(noticeContent ? noticeContent.type : '') === 'replace_payment' && (
                            <blockquote>
                                <li>
                                    您於
                                    {moment(orderData ? orderData.date : '').format('YYYY/MM/DD LT')}
                                    蓮心專案申請換拍已核定成功，訊息如下：
                                </li>
                                <li>申請編號：{orderData ? orderData.shipment_id : ''}</li>
                                <li>專案起訖：
                                    {moment(orderData ? orderData.contract_start_time : '').format('YYYY/MM/DD')}
                                    -
                                    {moment(orderData ? orderData.contract_end_time : '').format('YYYY/MM/DD')}
                                </li>
                                <li>目前換拍次數：{orderData ? orderData.replace_times : ''}</li>
                                <li className="padding-top-40px"></li>
                                {orderData && orderData.replace_times === 2 && (
                                    <li style={{ color: 'red', }}>
                                        提醒您！<br />
                                        你於合約期間內已達換拍上限，本合約將視為期滿，請聯繫客服繳清未到期之所有應付款項，以取得具高殘值的球拍所有權。<br />
                                        第二次換拍需先行支付蓮心保障費新台幣899元，請點選下列按鈕付款，我們將於您付款後10天內出貨。
                                    </li>
                                )}
                                {orderData && orderData.replace_times !== 2 && (
                                    <li>提醒您！首次更換球拍需先行支付蓮心保障費新台幣599元，二次換拍需先行支付蓮心保障費新台幣899元，請點選下列按鈕付款，我們將於您付款後10天內出貨。</li>
                                )}
                                <li className="margin-top-20px margin-bottom-20px">
                                    <Link className="button" to={'../rent_contract_detail/' + (orderData ? orderData.order_id : '')}>
                                        前往專案付款
                                    </Link>
                                </li>
                                <li>再次感謝您的使用。</li>
                                <li className="margin-top-20px margin-bottom-20px">
                                    <Link className="button" to={'/my_info/rent_contract_list'}>
                                        回到羽你蓮心
                                    </Link>
                                </li>
                                <li className="padding-bottom-40px"></li>
                            </blockquote>
                        )}
                        {/*蓮心專案申請換拍核定失敗通知*/}
                        {(noticeContent ? noticeContent.type : '') === 'replace_failed' && (
                            <blockquote>
                                <li>
                                    您於
                                    {moment(orderData ? orderData.date : '').format('YYYY/MM/DD LT')}
                                    蓮心專案申請換拍經核定為條件不符，訊息如下：
                                </li>
                                <li>申請編號：{orderData ? orderData.shipment_id : ''}</li>
                                <li>專案起訖：
                                    {moment(orderData ? orderData.contract_start_time : '').format('YYYY/MM/DD')}
                                    -
                                    {moment(orderData ? orderData.contract_end_time : '').format('YYYY/MM/DD')}
                                </li>
                                <li>目前換拍次數：{orderData ? orderData.replace_times : ''}</li>
                                <li className="padding-top-40px"></li>
                                <li>我們將於10個工作天內將原商品寄回，再次感謝您的使用。</li>
                                <li>若您有任何需求與疑問，請洽本公司客服專員為您服務，</li>
                                <li>•   客服專線：
                                    <a href="tel:0800388080">
                                        0800 - 388 - 080
                                    </a>
                                </li>
                                <li>•   客服信箱：
                                    <a href="matilti:service@bonny-live.com">
                                        service@bonny-live.com
                                    </a>
                                </li>
                                <li>本公司客服專員將於收到通知後為您服務。</li>
                                <li className="margin-top-20px margin-bottom-20px">
                                    <Link className="button" to={'/my_info/rent_contract_list'}>
                                        回到羽你蓮心
                                    </Link>
                                </li>
                                <li className="padding-bottom-40px"></li>
                            </blockquote>
                        )}
                        {/*蓮心專案取消專案核定通知*/}
                        {(noticeContent ? noticeContent.type : '') === 'cancel' && (
                            <blockquote>
                                <li>
                                    您於
                                    {moment(orderData ? orderData.date : '').format('YYYY/MM/DD LT')}
                                    蓮心專案申請取消專案已核定成功，訊息如下:
                                </li>
                                <li>取消編號：{noticeContent ? noticeContent.order_id : ''}</li>
                                <li>專案起訖：
                                    {moment(orderData ? orderData.contract_start_time : '').format('YYYY/MM/DD')}
                                    -
                                    {moment(orderData ? orderData.contract_end_time : '').format('YYYY/MM/DD')}
                                </li>
                                <li>球拍編號：{orderData ? orderData.racket_number : ''}</li>
                                <li>取消球拍：{orderData ? orderData.racket : ''}</li>
                                <li className="padding-top-40px"></li>
                                <li>再次感謝您的使用。</li>
                                <li className="margin-top-20px margin-bottom-20px">
                                    <Link className="button" to={'/my_info/rent_contract_list'}>
                                        回到羽你蓮心
                                    </Link>
                                </li>
                                <li className="padding-bottom-40px"></li>
                            </blockquote>
                        )}
                        {/*蓮心專案蓮心專案申請換拍出貨通知*/}
                        {(noticeContent ? noticeContent.type : '') === 'shipment' && (
                            <blockquote>
                                <li>
                                    您的蓮心專案此次申請款項已完成，我們將於10個工作天內到貨，訊息如下：
                                </li>
                                <li>球拍編號：{shipmentData ? shipmentData.racket_number : ''}</li>
                                <li>付款日期：{moment(shipmentData ? shipmentData.date : '').format('YYYY/MM/DD LT')}</li>
                                <li>出貨日期：{moment(shipmentData ? shipmentData.shipment_date : '').format('YYYY/MM/DD LT')}</li>
                                <li className="padding-top-40px"></li>
                                <li>再次感謝您的使用。</li>
                                <li className="margin-top-20px margin-bottom-20px">
                                    <Link className="button" to={'/my_info/rent_contract_list'}>
                                        回到羽你蓮心
                                    </Link>
                                </li>
                                <li className="padding-bottom-40px"></li>
                            </blockquote>
                        )}
                    </ul>
                </div>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    data: state.notificationReducer,
    loading: state.notificationReducer.shouldLoadingOpen,
})

const mapDispatchToProps = {
    getRentContentNotification: (id) => getRentContentNotification(id),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoticeDetail));
