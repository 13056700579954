import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import {
    getRacketOrderDetail,
    createRent,
    deleteReplace,
    getRacketOrderList,
    confirmUserMail,
    belongUpdate,
    setIsGetPaymentRes,
    changePaymentMethod
} from "../../../actions";
import { LoadingIndicator, InvoiceField } from "../../Common";
import { Wrapper } from "./styled";
import store from "../../../store";
import { localStorageUtility } from "../../../utilities";
import RacketInfoBeforeContractSign from "./RacketInfoBeforeContractSign";
import RacketInfoAfterContractSign from "./RacketInfoAfterContractSign";
import ReplaceCancelLightBox from "./ReplaceCancelLightBox";

const contractStatusEnum = {
    notHaveAContract: 0,
    contracting: 1, // 簽約中 押金才有，現已不用
    duringContractPeriod: 2,
    contractCanceled: 3,
    contractFinishedAndReceiveAllPay: 4
};

class RacketDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: this.props.match.params.id,
            usersearch: [],
            inviteeIndex: -1,
            userInfo: localStorageUtility("getAndParse", "myData"),
            SignUpStatus: true,
            CancelStatus: true,
            todos: [],
            rentContent: {
                racket_id: "",
                paymenttype: "",
                amount: "",
                user_id: "",
                user: "",
                email: "",
                phone: "",
                address: "",
                gift: "",
                payment_supplier: "",
                payment_auth_status: "",
                racket_belong: "",
                creditcard_expiration_date: "",
                order_id: "",
                payment_id: ""
            },
            invoiceInfo:{
                // default value
                invoiceType: 'twoCopies',
                invoiceCheck: true,
                vatNumber: '',
                print: 0,
                donation: 0,
                loveCode: '',
                carruerType: 1,
                carruerNum: '',
                errMessage: '',
            },
            userQualification: false
        };
    }

    componentDidMount() {
        const { orderId, userInfo } = this.state;
        store.dispatch(setIsGetPaymentRes(false));
        this.props.getRacketOrderDetail(orderId);
        this.props.getRacketOrderList();
        this.props.confirmUserMail(userInfo.email);
    }

    // cann't put in didUpdate because when props doesn't change, didUpdate wont set new state(like previous page function)
    componentWillReceiveProps(nextProps) {
        //更新付款資訊
        const { racketOrderDetail } = nextProps;
        const { orderId } = this.state;
        if( racketOrderDetail && racketOrderDetail.order ){
            this.setState({
                rentContent:{
                    racket_id: racketOrderDetail.order[0].racket_id.id,
                    paymenttype: racketOrderDetail.order[0].payment_type,
                    amount: '1',
                    user_id: racketOrderDetail.order[0].user_id.id,
                    user: racketOrderDetail.order[0].user,
                    email: racketOrderDetail.order[0].email,
                    phone: racketOrderDetail.shipment[0].phone,
                    address: racketOrderDetail.shipment[0].address,
                    gift: racketOrderDetail.order[0].gift,
                    payment_supplier: racketOrderDetail.order[0].payment_supplier,
                    payment_auth_status: racketOrderDetail.order[0].payment_auth_status,
                    racket_belong: racketOrderDetail.order[0].racket_belong,
                    creditcard_expiration_date: racketOrderDetail.order[0].creditcard_expiration_date,
                    order_id: orderId,
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {

        // 黑名單 或是 大力士 判斷
        if (
            this.props.userEmailConfirm &&
            prevProps.userEmailConfirm !== this.props.userEmailConfirm
        ) {
            const { userEmailConfirm } = this.props;
            const isBlockedUser =
                userEmailConfirm && userEmailConfirm.isBlockedUser;
            const isHercules = userEmailConfirm && userEmailConfirm.isHercules;

            if (isBlockedUser === false && isHercules === false) {
                this.setState({
                    userQualification: true
                });
            }
        }

        if (this.props.isGetPaymentRes === true) {
            document.getElementById("purchase_form").submit();
        }
    }

    handleCreateRent = (action) => {
        if(action === 'replacePayment'){
            if(this.state.invoiceInfo.invoiceCheck === false) {
                alert(this.state.invoiceInfo.errMessage);
            } else{
                delete this.state.invoiceInfo.errMessage
                delete this.state.invoiceInfo.invoiceCheck
                delete this.state.invoiceInfo.invoiceType
                this.props.createRent( { ...this.state.rentContent, ...this.state.invoiceInfo });
            }
        } else{
            this.props.createRent(this.state.rentContent);
        }
    };

    handleChangePaymentMethod = () => {
        this.props.changePaymentMethod({order_id: this.state.rentContent.order_id,});
    };

    LightBoxCustomerServiceOpen = (lightBoxText) => {
        this.handleInformationHidden("lightbox-customer-service", 'remove');
        if (lightBoxText === "isReachTheUpperLimit") {
            document.getElementById("serviceTitle").innerHTML =
                "此款式申請已達上限！";
        } else {
            document.getElementById("serviceTitle").innerHTML = "聯絡客服";
        }
    }

    handleInformationHidden(element, method) {
        const domElement = document.getElementById(element);
        domElement.classList[method]("close");
    }

    handleOnChange = (element, e) => {
        var changeValue = "";
        var updateCheck = 0;

        if (element === "racket_belong") {
            changeValue = e.target.value;
            updateCheck = 1;
        }
        if (updateCheck === 1) {
            this.setState(prevState => ({
                rentContent: {
                    ...prevState.rentContent,
                    [element]: changeValue
                }
            }));
        }
    };

    handleChangeInvoiceInfoState = (invoiceInfo)=>{
        this.setState({
            invoiceInfo:{
                ...invoiceInfo
            }
        })
    }

    belongUpdateSend = () => {
        //send rent
        let racketBelong =
            this.state.rentContent && this.state.rentContent.racket_belong;
        let notifyMessage = "";
        //confirm form

        var formCheck = 1;
        if (racketBelong === 0 || !racketBelong) {
            notifyMessage = "請確認選擇球拍歸屬！";
            formCheck = 0;
        }

        if (formCheck === 1) {
            //發送訂單
            this.props.belongUpdate({
                order_id: this.state.rentContent.order_id,
                racket_belong: racketBelong
            });
        } //show message if form value check failed
        else {
            alert(notifyMessage);
        }
    }

    render() {
        const { racketOrderList, racketOrderDetail } = this.props;
        const orderContent =
            racketOrderDetail.order &&
            racketOrderDetail.order[
                racketOrderDetail && racketOrderDetail.order.length - 1
            ];
        const rentLevel = orderContent && orderContent.tag;
        // 抓取 該專案已經簽約中的球拍數量
        const numberOfLeases =
            racketOrderList &&
            racketOrderList.filter( n => (n.contract_status === 1 || n.contract_status === 2) &&
                n.tag === rentLevel
            ).length;
        const payment = racketOrderDetail.payment && racketOrderDetail.payment;
        const shipment =
            racketOrderDetail.shipment && racketOrderDetail.shipment;
        const paymentLastID =
            orderContent &&
            orderContent.present_payment_id &&
            orderContent.present_payment_id.id;
        const shipmentLastID =
            orderContent &&
            orderContent.present_shipment_id &&
            orderContent.present_shipment_id.id;

        const contractStatus = orderContent && orderContent.contract_status; //合約狀態 0未簽約 1簽約中 2已簽約 3取消簽約

        // 如果 contractStatus === 3 或 禮物選擇不是 1 的話，會 return none
        const getCancelProgramButton = () => {
            var sevenDayTrial = orderContent && orderContent.contract_test_time;
            var invoiceOpeningTime = moment(parseInt(orderContent.createdAt)).add(1, "h").valueOf();
            var orderGift = orderContent.gift;

            // 有簽約
            if (
                contractStatus !== 3 &&
                contractStatus !== 0 &&
                orderGift === 1
            ) {
                if (
                    sevenDayTrial >= new Date().valueOf() &&
                    new Date().valueOf() >= invoiceOpeningTime
                ) {
                    return [
                        <div
                            key={1}
                            onClick={() => {
                                this.props.history.push("../cancel_rent_contract/" + orderContent.id);
                            }}
                            className="button"
                        >
                            取消專案
                        </div>
                    ];
                } else if (new Date().valueOf() <= invoiceOpeningTime) {
                    return [
                        <div
                            key={1}
                            className="button button-cancel"
                            onClick={this.LightBoxCustomerServiceOpen}
                        >
                            取消專案
                            <p>需等待訂單成立後一小時，才可取消此專案！</p>
                        </div>
                    ];
                }
            // 未簽約
            } else if (contractStatus === 0) {
                return [
                    <div
                        key={1}
                        onClick={() => {
                            this.props.history.push( "../cancel_rent_contract/" + orderContent.id);
                        }}
                        className="button"
                    >
                        取消申請
                    </div>
                ];
            }
        };

        const getPaymentButton = () => {
            const paymentStatus = orderContent && orderContent.payment_status; //0尚未付錢 1未定義 2定期定額ing 3完成所有付款
            const replacePaymentStatus =
                orderContent && orderContent.replace_payment_status; //換拍付費狀態 0無換拍/換拍付款完成 1換拍處理中
            // 須為 未簽約並未付款，且目前該專案已經租借的方案數量小於2支，使用者不是黑名單或大力士的狀態下，方可付款租借

            if (
                contractStatus === 0 &&
                paymentStatus === 0 &&
                numberOfLeases < 2 &&
                this.state.userQualification === true
            ) {
                return [
                    <div key={3} className="button" onClick={this.handleCreateRent}>
                        我要付款
                    </div>
                ];
            // 換拍中，且審核過
            } else if ( contractStatus === 2 && replacePaymentStatus === 1 && 
                ( paymentStatus === 2 || paymentStatus === 3 )
            ) {
                const parentShipmentId =
                    shipment &&
                    orderContent &&
                    orderContent.present_shipment_id.parent_id;
                const shipmentArr =
                    shipment &&
                    shipment.filter(n => n.id === parentShipmentId);
                const replaceShipmentInfo =
                    shipment && shipmentArr && shipmentArr[0];
                const replaceStatus =
                    shipment &&
                    orderContent &&
                    replaceShipmentInfo.replace_status; //0 using 1 processing 2 confirmed 3reject
                if (replaceStatus === 2) {
                    return [                        
                        <InvoiceField 
                            key={3}
                            handleChangeParentState = {this.handleChangeInvoiceInfoState }
                        />,
                        <div key={4} className="button" onClick={()=>{ this.handleCreateRent('replacePayment') }}>
                            換拍付款
                        </div>
                    ];
                }
            // 應該是 dead code because contract status shouldn't be 1 now
            } else if (
                contractStatus === 1 &&
                paymentStatus === 1 &&
                numberOfLeases <= 2
            ) {
                return [
                    <div key={3} className="button" onClick={this.handleCreateRent}>
                        繼續付款
                    </div>
                ];
            } else if (
                contractStatus === 0 &&
                paymentStatus === 0 &&
                numberOfLeases >= 2
            ) {
                return [
                    <div
                        key={3}
                        className="button button-cancel"
                        onClick={() => {
                            this.LightBoxCustomerServiceOpen("isReachTheUpperLimit");
                        }}
                    >
                        我要付款
                    </div>
                ];
            }
        };

        const getRacketInfo = () => {
            if (contractStatus === contractStatusEnum.notHaveAContract) {
                return (
                    <RacketInfoBeforeContractSign
                        orderContent={orderContent}
                        rentReducerData={this.props.rentReducerData}
                        shipment={shipment}
                        payment={payment}
                        getCancelProgramButton={getCancelProgramButton}
                        getPaymentButton={getPaymentButton}
                        history={this.props.history}
                    />
                );
            } else {
                const lastPaymentDetail =
                    payment &&
                    payment[
                        payment.filter(n => n.id === paymentLastID).length - 1
                    ];
                const lastShipmentDetail =
                    shipment &&
                    shipment[
                        shipment.filter(n => n.id === shipmentLastID).length - 1
                    ];
                const shipmentLength = shipment && shipment.length;
                return (
                    <RacketInfoAfterContractSign
                        orderContent={orderContent}
                        rentReducerData={this.props.rentReducerData}
                        shipment={shipment}
                        payment={payment}
                        contractStatus={contractStatus}
                        getCancelProgramButton={getCancelProgramButton}
                        getPaymentButton={getPaymentButton}
                        lastPaymentDetail={lastPaymentDetail}
                        lastShipmentDetail={lastShipmentDetail}
                        shipmentLength={shipmentLength}
                        history={this.props.history}
                        handleOnChange={this.handleOnChange}
                        belongUpdateSend={this.belongUpdateSend}
                        handleInformationHidden={this.handleInformationHidden}
                        LightBoxCustomerServiceOpen={this.LightBoxCustomerServiceOpen}
                        racketBelong={this.state.rentContent && this.state.rentContent.racket_belong}
                        changeCardType={orderContent && orderContent.changeCardType}
                        handleChangePaymentMethod={this.handleChangePaymentMethod}
                    />
                );
            }
        };

        return (
            <Wrapper key={0}>
                <h1
                    style={{
                        opacity: "0",
                        position: "fixed",
                        zIndex: "-999999",
                        left: "-100vw",
                        top: "-100vh",
                        width: "0px",
                        height: "0px"
                    }}
                >
                    BonnyBadminton 雲羽集 我的蓮心專案
                </h1>
                <LoadingIndicator shouldOpen={this.props.loading} />

                <div id="RacketDetail_program_content">
                    <div className="title">
                        <h2> 羽你蓮心專案 </h2>
                    </div>

                    {orderContent && getRacketInfo()}

                    <ReplaceCancelLightBox 
                        handleInformationHidden = {this.handleInformationHidden}
                        shipment = {shipment}
                        deleteReplace = {this.props.deleteReplace}
                        order_id = { racketOrderDetail && racketOrderDetail.order && racketOrderDetail.order[0].id }
                        shipment_id = { racketOrderDetail && racketOrderDetail.order && racketOrderDetail.shipment[0].id }
                        payment_id = { racketOrderDetail && racketOrderDetail.order && racketOrderDetail.payment[0].id }
                    />


                    {/*LightBox ----- 聯絡客服 */}
                    <div
                        id="lightbox-customer-service"
                        className="lightBox-explain close"
                    >
                        <div className="lightBox-explain-Content">
                            <div className="lightBox-explain-Content-inside">
                                <div
                                    className="lightBox-inside-close-button"
                                    onClick={() => {
                                        this.handleInformationHidden(
                                            "lightbox-customer-service",
                                            "add"
                                        );
                                    }}
                                >
                                    X
                                </div>
                                <div id="serviceTitle" className="LB-C-title">
                                    聯絡客服
                                </div>
                                <div className="LB-C-Description">
                                    <ul>
                                        <li>若您有任何疑問以及需求，</li>
                                        <li>請洽本公司客服專員為您服務，</li>
                                        <li>
                                            客服專線：
                                            <a href="tel:0800388080">
                                                0800 - 388 - 080
                                            </a>
                                        </li>
                                        <li>
                                            客服信箱：
                                            <a href="mailto:service@bonny-live.com">
                                                service@bonny-live.com
                                            </a>
                                        </li>
                                        <li>
                                            本公司客服專員將於收到通知後為您服務。
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <hr />
                            <div
                                className="lightBox-outside-close-button"
                                onClick={() => {
                                    this.handleInformationHidden(
                                        "lightbox-customer-service",
                                        "add"
                                    );
                                }}
                            >
                                返回
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    rentReducerData: state.rentReducer,
    racketOrderDetail: state.rentReducer.racketOrderDetail,
    loading: state.rentReducer.shouldLoadingOpen,
    userEmailConfirm: state.rentReducer.userEmailConfirm,
    racketOrderList: state.rentReducer.racketOrderList.RacketOrder,
    isGetPaymentRes: state.rentReducer.isGetPaymentRes
});

const mapDispatchToProps = {
    getRacketOrderDetail: id => getRacketOrderDetail(id),
    createRent: data => createRent(data),
    deleteReplace: data => deleteReplace(data),
    confirmUserMail: data => confirmUserMail(data),
    belongUpdate: data => belongUpdate(data),
    getRacketOrderList: getRacketOrderList,
    changePaymentMethod: data => changePaymentMethod(data),
};


export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(RacketDetail)
);
