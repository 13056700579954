import React, { Component } from "react";
import { connect } from "react-redux";
import ThirdParty from './ThirdPartyButton';
import SubmitButton from './SubmitButton';
import RegisterButton from './RegisterButton';
import RememberMeClick from './RememberMeClick';
import { Wrapper } from './styled';
import { FormControl, LoadingIndicator, GaSetting } from "../Common";
import {
    fetchLoginFB,
    fetchlogin,
} from "../../actions";
import UAParser from 'ua-parser-js';
import moment from 'moment-timezone';
import { validateEmail, localStorageUtility } from '../../utilities';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: localStorageUtility('getAndParse', 'Remember') || '',
            check: localStorageUtility('getAndParse', 'rememberMe'),
            fbAccessToken : [],
            agent:{},
        }

        const userInfo = localStorageUtility('getAndParse', 'myData')
        if (userInfo && userInfo.id) {
            //  使用者為登入狀態，導到 myInfo
            window.location.href = "/my_info";
        } else {
            document.cookie = 'myinfo=; Max-Age=-99999999;';
        }

        this.accountRef = React.createRef();
        this.pwRef = React.createRef();

    }

    componentDidMount() {

        var parser = new UAParser();
        let ua= navigator.userAgent
        this.setState({
            agent :{
                browser: parser.setUA(ua).getBrowser(),
                os: parser.setUA(ua).getOS(),
                device: parser.setUA(ua).getDevice(),
            }
        }) 
    }

    componentDidUpdate(prevProps, prevState){
        if( this.props.userLoginLoading && 
            this.props.userLoginLoading === true){
            document.getElementById("Loading").classList.add("close");
        }
    }

    handleInputChange = (event) => {
        // 從 event object 拿到 target
        const target = event.target;

        // 從 target.type 可以知道欄位的 type
        // 或從 target.value 取出輸入的欄位值
        const value = target.value;
        // 從 target.name 得到該欄位設定的 name
        const name = target.name;

        // 分別更新不同 name 欄位的 state
        this.setState({
            [name]: value
        });
    }

    async loginWaitSend (e) {
        let playerId = localStorageUtility('get', 'playerId')
        const Body = {
            email: this.state.account || this.accountRef.current.value,
            password: this.state.password || this.pwRef.current.value,
            rememberMe: ( localStorageUtility('get', 'rememberMe') === 'true'),
            agent: this.state.agent,
            playerId: playerId
        };
        await document.getElementById("Loading").classList.remove("close");
        await this.props.onFetchLogin(Body);
    }

    handleSubmit = (e) => {
        e.preventDefault();

        //驗證電子郵件
        var strEmail = this.state.account || this.accountRef.current.value ;

        if (!!strEmail) {
            if ( validateEmail(strEmail) === true ) {

                let isRememberMe = localStorageUtility('get', 'rememberMe');
                if (isRememberMe === 'false') {
                    let expiresTime = moment().tz("Asia/Taipei").add(1, "day").format()
                    localStorageUtility('set', 'expiresTime', expiresTime);
                } else {
                    // 點選記住我，設置登入過期時間
                    let expiresTime= moment().tz("Asia/Taipei").add(7, "day").format()
                    localStorageUtility('set', 'expiresTime', expiresTime);
                }
                this.loginWaitSend();
            } else {
                alert("請輸入正確的E-Mail帳號");
            }
        } else {
            alert("請輸入正確的E-Mail帳號");
        }
    }

    async fbLoginWaitSend (e) {
        const resp = this.state.fbAccessToken;
        let agent = this.state.agent
        let playerId = localStorageUtility('get', 'playerId');

        await document.getElementById("Loading").classList.remove("close");
        await this.props.onFetchLoginFB(resp, {}, {agent, playerId: playerId});
    }

    onLoginFacebook(resp) {
        if (!resp.accessToken) {
            alert('登入 FB 失敗');
            return;
        }else if (!resp.email) {
            alert('必須提供 email');
            return;
        }else{
            // document.getElementById("Loading").classList.remove("close");
            let expiresTime = moment().tz("Asia/Taipei").add(7, "day").format();
            localStorageUtility('set', 'expiresTime', expiresTime);

            // this.props.onFetchLoginFB(resp);
            this.setState({fbAccessToken: resp})
            this.fbLoginWaitSend();
            // alert(resp);
            // alert(this.state && this.state.fbAccessToken);
        }
    }

    render() {
        return (
            <Wrapper>
                <form className="main-form">
                    <h1 className="title">我要登入</h1>
                    <div className="form-control">
                        <input name='account' type="text" autocomplete="username" value={this.state.account} onChange={this.handleInputChange} placeholder="帳號(請填入E-Mail)" 
                        ref ={this.accountRef}/>
                    </div>
                    <div className="form-control">
                        <input name='password' type="password" autocomplete="current-password" value={ !this.state.password ? "" : this.state.password} onChange={this.handleInputChange} placeholder="密碼" 
                        ref ={this.pwRef}/>
                    </div>
                    <SubmitButton onClick={this.handleSubmit} />
                    <div style={{display: `flex`, justifyContent: `space-between`, alignItems: `center`}}>
                        <RememberMeClick remember={this.state.account} onClick={this.handleclick} check={this.state.check} />
                        <GaSetting
                            data = {{
                                className: 'forget',
                                category: 'MyBonny', 
                                action: 'Click', 
                                label: 'User is forget password', 
                                tag: 'link',
                                path: '/forget',
                                style : {
                                    color: `#000`,
                                    fontSize: `18px`,
                                    fontWeight: `bold`,
                                },
                            }}
                        >   
                            <p>忘記密碼?</p>
                        </GaSetting>
                    </div>
                    <FormControl>
                        <GaSetting
                            data = {{
                                category: 'MyBonny', 
                                action: 'MyBonny-member-FB', 
                                label: '', 
                                tag: 'div',
                            }}
                        >
                            <ThirdParty
                                onLoginFacebook={resp => this.onLoginFacebook(resp)}
                            />
                        </GaSetting>
                    </FormControl>
                    <hr style={{paddingBottom: '10px', borderStyle: 'none',borderTop: '0.5px solid rgba(0,0,0,.5)',}}/>
                    <h1 className="Thirdtext">還不是會員嗎?</h1>
                    <RegisterButton />
                </form>
                <div className='placeholder'></div>
                <LoadingIndicator/>
            </Wrapper>
        );
    }
}


const mapStateToProps = state => ({
    userLoginLoading: state.loginReducer.userLoginLoading,
});

const mapDispatchToProps = (dispatch) => ({
    onFetchLoginFB: (resp, body = {}, agent) => dispatch(fetchLoginFB(resp, body, agent)),
    onFetchLogin: (Body) => {
        dispatch(fetchlogin(Body))
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
