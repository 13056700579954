import React, { Component } from "react";
import { Wrapper } from './styled';
import { GaSetting } from "../../../Common";

class LandingPageB extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notAndroid: false,
        }
    }

    componentDidMount(){
        if (navigator.userAgent.indexOf("Android") <= -1) {
            this.setState({
                notAndroid: true
            })
        }
    }

    render() {
        return (
            <Wrapper key="1">
                <GaSetting
                    data = {{
                        name: 'componentDidMount', 
                        category: '3D', 
                        action: '3D_PV', 
                        label: '',
                    }}
                />
                <div id="WRAPPER">
                    <div id="HEADER">
                        <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/middleLevel/landingPageB/key.jpg" width="100%" alt=""/>
                        <GaSetting 
                            data = {{ 
                                className: "HEADER-buy",
                                category: '3D', 
                                action: '3D_ShoppingCart_Click_HeaderBuy', 
                                label: '',
                                tag: 'link',
                                path: { 
                                    pathname: "/rent",
                                    state: {
                                        "level": 'medium',
                                    }
                                },
                            }}
                        >
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/middleLevel/landingPageB/btn-buy-3.png" alt="" width="100%"/>
                        </GaSetting>
                    </div>
                    {this.state.notAndroid &&
                        <div id="WRAPPER2">
                            <div className="iframe-16x9">
                                <iframe width="100%" src="https://www.youtube.com/embed/_jKiw6CBvdY" frameBorder="0"
                                    title="Bonny 波力 中階拍"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen
                                    style={{ margin: '0 auto'}} >
                                </iframe>
                            </div><br/>
                        </div>
                    }
                    <div id="CONTENT-A">
                        <p>
                            型號：FT68 PK(櫻之粉羽) / FT68 PP(神秘紫羽) <br/>
                            拍框材質：航空級碳纖維+烏缺<br/>
                            拍框類型：窄邊小平頭<br/>
                            中管材質：航空級碳纖維+烏缺<br/>
                            中管軟硬度：M（適中）<br/>
                            球拍長度：675±1mm<br/>
                            平衡：295mm±0mm（未穿線）<br/>
                            柄號：2#/G4<br/>
                            最高磅數：24磅<br/>
                            重量：7U（未穿線）<br/>
                            球拍屬性：攻防拍<br/>
                        </p>
                        <div id="CONTENT-A-BUY">
                            <GaSetting
                                data = {{ 
                                    category: '3D', 
                                    action: '3D_ShoppingCart_Click_atDetail', 
                                    label: '',
                                    tag: 'link',
                                    path: { 
                                        pathname: "/rent",
                                        state: {
                                            "level": 'medium',
                                        }
                                    },
                                }}
                            >
                                <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/middleLevel/landingPageB/btn-buy-2.png" alt=""/>
                            </GaSetting>
                        </div>
                    </div>
                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/middleLevel/landingPageB/img_1.jpg" width="100%" alt=""/>
                    <div className="PROGRAM">
                        <div>
                            <div>
                                月繳88元 / 年繳758元 <br/> 一次付清 2999 元
                            </div>
                            <div>
                                <GaSetting 
                                    data = {{ 
                                        category: '3D', 
                                        action: '3D_ShoppingCart_Click', 
                                        label: '', 
                                        tag: 'link',
                                        title: '3D_ShoppingCart',
                                        path: {
                                            pathname: '/rent',
                                            state: {
                                                "level": 'medium',
                                            }
                                        },
                                        style: {cursor: 'pointer'}, 
                                    }}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/middleLevel/landingPageB/btn-buy-4.png" width="100%" alt="2D_ShoppingCart" />
                                </GaSetting>
                            </div>
                        </div>
                    </div>
                    <div className="SERVICE">
                        <div>
                            <div>
                                貼心解說及訂購協助 <br/> 0800- 388 - 080
                            </div>
                            <div>
                                <GaSetting 
                                    data = {{ 
                                        category: '3D', 
                                        action: '3D_Service_Click', 
                                        label: '', 
                                        title: '3D_ServiceClick',
                                        path: 'https://docs.google.com/forms/d/1JK59YJ--5g3dTrIVyX6WX6hZCc6zyE2lLRsHyrelXK4/edit',
                                        target: '_bank', 
                                        style: {cursor: 'pointer'},
                                    }}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/middleLevel/landingPageB/btn-go-2.png" alt="貼心解說及訂購協助"/>
                                </GaSetting>
                            </div>
                        </div>
                    </div>
                    <div className="DPURCHASE">
                        <div>
                            <div>
                                選購其他 <br/> 一年保固球拍
                            </div>
                            <div>
                                <GaSetting 
                                    data = {{ 
                                        category: '3D', 
                                        action: '3D_toEC_Click', 
                                        label: '', 
                                        title: '3D_toECShopClick',
                                        path: 'http://www.bonny-live.com/Product/List?tag=68%E5%85%8B%E6%9C%80%E8%BC%95%E6%8B%8D',
                                        target: '_bank',
                                        style: {cursor: 'pointer'},
                                    }}
                                >
                                    <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/middleLevel/landingPageB/btn-buy-5.png" alt="官網直購1882元"/>
                                </GaSetting>
                            </div>
                        </div>
                    </div>
                    <div className="CASE">
                        <GaSetting 
                            data = {{ 
                                category: '3D', 
                                action: '3D_to2D_Click', 
                                label: '', 
                                tag: 'link',
                                title: '3D_to2DClick',
                                path: '/limited',
                                style: {cursor: 'pointer'}, 
                            }}
                        >
                            日付2元方案
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/middleLevel/landingPageB/btn-add.png" alt=""/>
                        </GaSetting>
                        <GaSetting 
                            data = {{ 
                                category: '3D', 
                                action: '3D_to4D_Click', 
                                label: '', 
                                tag: 'link',
                                title: '3D_to4DClick',
                                path: '/classic',
                                style: {cursor: 'pointer'}, 
                            }}
                        >
                            日付4元方案
                            <img src="https://d2vsnoltqccfhr.cloudfront.net/image/racket/middleLevel/landingPageB/btn-add.png" alt=""/>
                        </GaSetting>
                    </div>
                </div>  

            </Wrapper>
        );
    }
}


export default LandingPageB;
