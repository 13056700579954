import React from 'react';
import { GaSetting } from "../../Common";
import { Wrapper } from './styled';

export default class OptionButtons extends React.Component {
    render() {
        const { data } = this.props;
        let notAndroid = false;
        if (navigator.userAgent.indexOf("Android") <= -1) {
            notAndroid = true;
        }
        return (
            data.landingPageCode === 'A' ?
            <Wrapper>
                <div className={`buttonsContent` +  data.landingPageCode}>
                    <div className={notAndroid ? `buttonsContent` +  data.landingPageCode + `__btns` : `buttonsContent` +  data.landingPageCode + `__btns android`}>
                        <div className={`buttonsContent` +  data.landingPageCode + `__btns__btnTop`}>
                            <GaSetting 
                                key = "indexLink_0"
                                data = {{
                                    className: 'index-badminton',
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-media-click', 
                                    label: '', 
                                    tag: 'button',
                                    path: '/media',
                                }}
                            >
                            </GaSetting>
                            <GaSetting 
                                key = "indexLink_1"
                                data = {{
                                    className: notAndroid ? 'index-badminton' : 'index-badminton hidden',
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-live-click', 
                                    label: '', 
                                    tag: 'button',
                                    target: '_bank',
                                    path: 'https://www.bonny-live.com/Show/List',
                                }}
                            >
                            </GaSetting>
                            <GaSetting 
                                key = "indexLink_2"
                                data = {{
                                    className: 'index-badminton', 
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-know-click', 
                                    label: '', 
                                    tag: 'button',
                                    target: '_bank',
                                    path: 'https://www.bonny-live.com/WebDocument/SportCategory',
                                }}
                            >
                            </GaSetting>
                            <GaSetting 
                                key = "indexLink_3"
                                data = {{
                                    className: 'index-badminton', 
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-equipment-click', 
                                    label: '',
                                    tag: 'button', 
                                    target: '_bank',
                                    path: 'https://www.bonny-live.com/Product/List?tag=%E7%BE%BD%E7%90%83%E6%8B%8D',
                                }}
                            >
                            </GaSetting>
                        </div>
                    
                        <div className={`buttonsContent` +  data.landingPageCode + `__btns__btnBottom`}>
                            <GaSetting 
                                key = "indexLink_4"
                                data = {{
                                    className: 'index-badminton',
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-rent-click', 
                                    label: '', 
                                    tag: 'link',
                                    path: '/rackets_lobby',
                                }}
                            >
                            </GaSetting>
                            <GaSetting 
                                key = "indexLink_5"
                                data = {{
                                    className: 'index-badminton',
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-sign-click', 
                                    label: '', 
                                    tag: 'link',
                                    path: '/activity_list',
                                    disabled : this.props.disableActivitySignUpButton
                                }}
                            >
                            </GaSetting>
                            <GaSetting 
                                key = "indexLink_6"
                                data = {{
                                    className: 'index-badminton', 
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-activity-click', 
                                    label: '', 
                                    tag: 'link',
                                    path: '/create_activity',
                                }}
                            >
                            </GaSetting>
                        </div>
                    </div>
                </div>
            </Wrapper>
            :
            <Wrapper>
                <div className={`buttonsContent` +  data.landingPageCode}>
                    <div className={notAndroid ? `buttonsContent` +  data.landingPageCode + `__btns` : `buttonsContent` +  data.landingPageCode + `__btns android`}>
                        <div className={`buttonsContent` +  data.landingPageCode + `__btns__btnTop`}>
                            <GaSetting 
                                key = "indexLink_0"
                                data = {{
                                    className: notAndroid ? 'index-badminton' : 'index-badminton hidden', 
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-live-click', 
                                    label: '', 
                                    tag: 'button',
                                    target: '_bank',
                                    path: 'https://www.bonny-live.com/Show/List',
                                }}
                            >
                            </GaSetting>
                            <GaSetting 
                                key = "indexLink_1"
                                data = {{
                                    className: 'index-badminton', 
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-equipment-click', 
                                    label: '',
                                    tag: 'button', 
                                    target: '_bank',
                                    path: 'https://www.bonny-live.com/Product/List?tag=%E7%BE%BD%E7%90%83%E6%8B%8D',
                                }}
                            >
                            </GaSetting>
                            <GaSetting 
                                key = "indexLink_2"
                                data = {{
                                    className: 'index-badminton', 
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-know-click', 
                                    label: '', 
                                    tag: 'button',
                                    target: '_bank',
                                    path: 'https://www.bonny-live.com/WebDocument/SportCategory',
                                }}
                            >
                            </GaSetting>
                            <GaSetting 
                                key = "indexLink_3"
                                data = {{
                                    className: 'index-badminton', 
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-activity-click', 
                                    label: '', 
                                    tag: 'link',
                                    path: '/create_activity',
                                }}
                            >
                            </GaSetting>
                        </div>
                    
                        <div className={`buttonsContent` +  data.landingPageCode + `__btns__btnBottom`}>
                            <GaSetting 
                                key = "indexLink_4"
                                data = {{
                                    className: 'index-badminton',
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-media-click', 
                                    label: '', 
                                    tag: 'link',
                                    path: '/media',
                                }}
                            >
                            </GaSetting>
                            <GaSetting 
                                key = "indexLink_5"
                                data = {{
                                    className: 'index-badminton',
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-sign-click', 
                                    label: '', 
                                    tag: 'link',
                                    path: '/activity_list',
                                    disabled : this.props.disableActivitySignUpButton,
                                }}
                            >
                            </GaSetting>
                            <GaSetting 
                                key = "indexLink_6"
                                data = {{
                                    className: 'index-badminton',
                                    category: `index-type` + data.landingPageCode + `-badminton`, 
                                    action: 'index-rent-click', 
                                    label: '', 
                                    tag: 'link',
                                    path: '/rackets_lobby',
                                }}
                            >
                            </GaSetting>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

OptionButtons.defaultProps = {
    data: []
};

