import { createAction } from 'redux-actions';
import { fetchObject, VALIDATE_ERROR_CODE } from "./fetchModule";
import { history } from '../index';
import { localStorageUtility } from '../utilities';

export const getUserRentData = createAction("GET_USER_RENT");
export const confirmUserEmailData = createAction("CONFIRM_USER_EMAIL");
export const setEmailUsedStatus = createAction("SET_EMAIL_USED_STATUS");
export const setRacketProgram = createAction("SET_RACKET_PROGRAM");
export const setRacketInformation = createAction("SET_RACKET_INFORMATION");
export const fetchRacketOrderDetail = createAction("FETCH_RACKET_ORDER_DETAIL_DATA");
export const updateCancelProgramOrReplaceRacket = createAction("UPDATE_CANCEL_PROGRAM_OR_REPLACE_RACKET");
export const deleteReplaceData = createAction("DELETE_REPLACE_DATA");
export const shouldLoadingOpen = createAction("SHOULD_LOADING_OPEN"); // Loading Mask ON
export const fetchRacketOrderData = createAction("GET_RACKET_ORDER_LIST");
export const setIsGetPaymentRes = createAction("SET_IS_GET_PAYMENT_RES"); // Application Success

function createForm(path, jsonForm, method = "post") {
    const form = document.createElement("form");
    form.id = "purchase_form";
    form.method = method;
    form.action = path;
    for (const key in jsonForm) {
        if (jsonForm.hasOwnProperty(key)) {
            const hiddenField = document.createElement("input");
            hiddenField.type = "hidden";
            hiddenField.name = key;
            hiddenField.id = key;
            hiddenField.value = jsonForm[key];
            form.appendChild(hiddenField);
        }
    }
    document.body.appendChild(form);
}

function parseResponse (response, dispatch) {
    let jsonparse;
    const contentType = response.headers.get('Content-Type');
    if (contentType !== null) {
        if (contentType.indexOf('text') > -1) {
            jsonparse = response.text()
        } else if (contentType.indexOf('form') > -1) {
            jsonparse = response.formData();
            dispatch(shouldLoadingOpen(true));
        } else if (contentType.indexOf('video') > -1) {
            jsonparse = response.blob();
        } else if (contentType.indexOf('json') > -1) {
            jsonparse = response.json();
        }

        return jsonparse;
    }
}

export const createRent = (data) => {
    //debugger
    return (dispatch) => {
        return fetchObject(`racketorder/payment/`, "", {
            method: "POST",
            body: JSON.stringify(data)
        })
            .then( (response) => {
                // 打開 Loading Mask
                dispatch(shouldLoadingOpen(true));
                // 確認json型態取值
                return parseResponse(response, dispatch).then((response) => {
                    return response;
                });
            })
            .then((json) => {
                if (json.form) {
                    if (data.order_id === undefined) {
                        window.alert('申請成功');
                    }
                    // 建立 Form
                    createForm(json.url, json.form);
                    return json.form;
                } else {
                    throw new Error(json.code);
                }
            })
            .then((data) => {
                dispatch(setIsGetPaymentRes(true));
            }, (error) => {
                // 關閉 Loading Mask
                dispatch(shouldLoadingOpen(false));

                // 錯誤狀態判斷 顯示
                switch(error.message) {
                    case "OVER_QUANTITY_LIMIT_OF_HIGH":
                    case "OVER_QUANTITY_LIMIT_OF_MEDIUM":
                    case "OVER_QUANTITY_LIMIT_OF_LOW":
                        window.alert('您選擇的方案已達申請上限，請選擇其他專案申請！');
                        history.push('/rackets_lobby');
                        break;
                    case "RACKET_PAYMENT_HAS_PROCESSING":
                        window.alert('您尚有一筆交易正在處理中，請您於十五分鐘或該筆交易結束之後，再重新進行付款。')
                        history.push('/my_info/rent_contract_list');
                        break;
                    default:
                        window.alert('請聯繫客服協助處理！');
                }
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

export const getRacketInformation = () => {
    return (dispatch) => {
        fetchObject(`racketinformation/list`, '', {
            method: 'GET',
        })
            .then(res => res.json())
            .then(
                (result) => {
                    dispatch(setRacketInformation(result));
                },
            )
            .catch(function (ex) {
                console.log("parsing failed", ex);
            });
    };
};

export const getUserRent = (id) => {
    return (dispatch) => {
        fetchObject(`racketorder/list/${id}`)
            .then(function(response) {
                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }

                    return jsonparse;
                }
            })
            .then((json) => {
                if (typeof json.length !== 'undefined') {
                    return json;
                } else {
                    throw new Error(json.code);
                }
            })
            .then((data) => {
                dispatch(getUserRentData(data));
            }, (error) => {
                switch(error.message) {
                    case "SOURCE_INVALID":
                    case "TOKEN_INVALID":
                    case "FAKE_USER":
                        window.alert('資料發生異常，請重新操作。');
                        break;
                    default:
                        window.alert('請聯繫客服協助處理！');
                }
                history.replace('/error_page');
            })
            .catch(function(ex) {
                console.log('parsing failed', ex);
            });
    }
}

export const confirmUserMail = (email) => {
    return (dispatch) => {
        fetchObject(`racketorder/emailcheck?email=${email}`)
            .then(function (response) {
                const contentType = response.headers.get('Content-Type');
                // 確認json型態取值
                let jsonparse = '';
                if (contentType != null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    }
                    if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                    }
                    if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    }
                    if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }
                }

                if (response.ok) return jsonparse;
                else
                    jsonparse.then(function(err) {});
            })
            .then(function (json) {
                dispatch(confirmUserEmailData(json));
            })
            .catch(function (ex) {
                alert(VALIDATE_ERROR_CODE(ex.code));
            });
    }
}

export const getRacketProgram = () => {
    return (dispatch) => {
        fetchObject(`racketprogram/list`)
            .then(res => res.json())
            .then(
                (result) => {
                    dispatch(setRacketProgram(result));
                    dispatch(shouldLoadingOpen(false));
                },
            )
    };
};

export const getRacketOrderList = (defaultQuery = {}) => {
    const userObj = localStorageUtility('getAndParse', 'myData');
    return (dispatch, getState) => {
        fetchObject(`racketorder/list/${userObj.id}`,)
            .then(function (response) {
                if (response.ok) return response.json();
                else
                    response.json().then(function (err) {
                    });
            })
            .then(function (json) {
                dispatch(shouldLoadingOpen(false));
                dispatch(fetchRacketOrderData({ RacketOrder: json }));
            })
            .catch(function (ex) {
                if (ex.name === 'AbortError') {
                } else {
                    alert(VALIDATE_ERROR_CODE(ex.code));
                }
            });
    };
};

export const getRacketOrderDetail = ( id ) => {
    return (dispatch, getState) => {
        fetchObject(`racketorder/${id}`)
            .then(function(response) {
                if (response.ok) return response.json();
                else
                    response.json().then(function(err) {
                        history.replace('/error_page');
                    });
            })
            .then(function(json) {
                let order = json.order.filter(data => data.id === Number(id));
                if (order) {
                    dispatch(shouldLoadingOpen(false));
                    dispatch(updateCancelProgramOrReplaceRacket(false));
                    dispatch(fetchRacketOrderDetail(json));
                    return json;
                } else {
                    switch(json.code) {
                        case "FAKE_USER":
                            window.alert('資料異常，請確認使用者帳戶是否正確。');
                            break;
                        default:
                            window.alert('請聯繫客服協助處理！');
                    }
                    history.replace('/my_info/rent_contract_list');
                }
            })
            .then((data) => {
            })
            .catch(function(ex) {
                console.log('parsing failed', ex);
                alert(VALIDATE_ERROR_CODE(ex.code));
            });
    };
};

export const cancelProgramOrReplaceRacket = ( data ) => {
    //debugger
    return (dispatch) => {
        fetchObject(`racketorder/update/`, "", {
            method: "POST",
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if
                    (response.ok) return response;
                else
                    response.then(function(err) {
                        history.replace('/error_page');
                    });
            })
            .then((response) => {
                dispatch(shouldLoadingOpen(true));
                dispatch(updateCancelProgramOrReplaceRacket(true));
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

export const deleteReplace = ( data ) => {
    //debugger
    return (dispatch) => {
        fetchObject(`racketshipmentrecord/delete`, "", {
            method: "POST",
            body: JSON.stringify(data)
        })
            .then(function (response) {
                if (response.ok) return response;
                else
                    response.then(function(err) {
                        history.replace('/error_page');
                    });
            })
            .then(function (json) {
                let jsonparse = '';
                const contentType = json.headers.get('Content-Type');
                //確認json型態取值
                if(contentType != null) {
                    if(contentType.indexOf('text') > -1) {
                        jsonparse = json.text()
                    }
                    if(contentType.indexOf('form') > -1) {
                        jsonparse = json.formData();
                    }
                    if(contentType.indexOf('video') > -1) {
                        jsonparse = json.blob();
                    }
                    if(contentType.indexOf('json') > -1) {
                        jsonparse = json.json();
                    }
                }
                if (!data.previous) {
                    return;
                }
                return jsonparse;
            })
            .then((resData) => {
                dispatch(deleteReplaceData(resData));
                // window.location.href = "/my_info/rent_contract_detail/"+data.order_id;
                // history.replace("/my_info/rent_contract_detail/"+data.order_id);

                dispatch(getRacketOrderList());
                dispatch(getRacketOrderDetail(data.order_id));
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}

export const changePaymentMethod = (data) => {
    //debugger
    return (dispatch) => {
        fetchObject(`racketorder/changeCreditCard`, "", {
            method: "POST",
            body: JSON.stringify(data)
        })
            .then((response) => {
                // 打開 Loading Mask
                dispatch(shouldLoadingOpen(true));

                // 確認json型態取值
                let jsonparse;
                const contentType = response.headers.get('Content-Type');
                if (contentType !== null) {
                    if (contentType.indexOf('text') > -1) {
                        jsonparse = response.text()
                    } else if (contentType.indexOf('form') > -1) {
                        jsonparse = response.formData();
                        dispatch(shouldLoadingOpen(true));
                    } else if (contentType.indexOf('video') > -1) {
                        jsonparse = response.blob();
                    } else if (contentType.indexOf('json') > -1) {
                        jsonparse = response.json();
                    }

                    return jsonparse;
                }
            })
            .then((json) => {
                console.log(json);
                if (json.form) {
                    // 建立 Form
                    createForm(json.url, json.form);
                    return json.form;
                } else {
                    throw new Error(json.code);
                }
            })
            .then((data) => {
                dispatch(setIsGetPaymentRes(true));
            }, (error) => {
                // 關閉 Loading Mask
                dispatch(shouldLoadingOpen(false));

                // 錯誤狀態判斷 顯示
                switch(error.message) {
                    case "RACKET_PAYMENT_HAS_PROCESSING":
                        window.alert('您尚有一筆交易正在處理中，請您於十五分鐘或該筆交易結束之後，再重新進行付款。');
                        history.push('/my_info/rent_contract_list');
                        break;
                    case "ORDER_NOT_FOUND":
                        window.alert('查無訂單資料，請重新操作。');
                        history.push('/my_info/rent_contract_list');
                        break;
                    case "CHANGE_CREDIT_CARD_ERROR":
                        window.alert('訂單狀態錯誤，請重新操作。');
                        history.push('/my_info/rent_contract_list');
                        break;
                    default:
                        window.alert('請聯繫客服協助處理！');
                }
            })
            .catch(function (ex) {
                console.log('parsing failed', ex)
            })
    }
}