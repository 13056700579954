import styled from 'styled-components';
import Config from "../../config";

export const Wrapper = styled.div`
    width: 100vw;
    height: 120px;
    position: relative;
    bottom: 0;
    background-color: #da263c;


    @media (min-width: 641px) and (max-width: 1279px){
        height: 100px;
    }
    @media (max-width: 640px) {
        height: 70px;
        position: fixed;
    }
    @media (max-width: 380px) {
        height: 60px;
    }

    .buttonsContentA {
        width: 100vw;
        height: 200px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 1280px){
            max-width: 960px;
        }
        @media (min-width: 641px) and (max-width: 1279px){
            max-width: 768px;
            height: 160px;
        }
        @media (max-width: 640px) {
            position: fixed;
            bottom: 0;
            max-width: 480px;
            height: 90px;
        }


        &__btns {
            flex: 1;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            &__btnTop {
                // flex: 1;
                width: calc( 100% / 7 * 4 );
                text-align: center;
                display: flex;

                @media (max-width: 640px) {
                    position: fixed;
                    width: calc( 100vw / 4 * 4);
                    top: 67px;
                }

                a, div, button {
                    // 清除Button樣式
                    margin: 0;
                    padding: 0;
                    border: unset;
                    outline: none;

                    // 清除a href樣式
                    text-decoration: none;
                    color: #000;

                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-position: center;
                    padding-bottom: calc( 100% / 4 );
                    position: relative;
                    background-repeat: no-repeat;
                    background-color: unset;

                    &:first-child {
                        background-image: ${ Config.isBonnyStar ?
                            'url("/assets/icons/indexButtons/desktopA/bonny_star.svg")'
                            :
                            'url("/assets/icons/indexButtons/desktopA/icon01.svg")'
                        };
                        @media (max-width: 640px) {
                            background-image: ${ Config.isBonnyStar ?
                                'url("/assets/icons/indexButtons/mobileA/bonny_star.svg")'
                                :
                                'url("/assets/icons/indexButtons/mobileA/icon01.svg")'
                            };
                        }
                    }
                    &:nth-child(2) {
                        background-image: url("/assets/icons/indexButtons/desktopA/icon02.svg");
                        @media (max-width: 640px) {
                            background-image: url("/assets/icons/indexButtons/mobileA/icon02.svg");
                        }
                    }
                    &:nth-child(2).hidden {
                        display: none;
                    }
                    &:nth-child(3) {
                        background-image: url("/assets/icons/indexButtons/desktopA/icon03.svg");
                        @media (max-width: 640px) {
                            background-image: url("/assets/icons/indexButtons/mobileA/icon03.svg");
                        }
                    }
                    &:nth-child(4) {
                        background-image: url("/assets/icons/indexButtons/desktopA/icon04.svg");
                        @media (max-width: 640px) {
                            background-image: url("/assets/icons/indexButtons/mobileA/icon04.svg");  
                        }
                    }
                }
            }

            &__btnBottom {
                // flex: 1;
                width: calc( 100% / 7 * 3 );
                text-align: center;
                display: flex;

                @media (max-width: 640px) {
                    position: absolute;
                    bottom: 0px;
                    // width: 70vw;
                    // max-width: 360px;
                    // width: 360px;
                    width: calc( 100vw / 4 * 3);
                }

                a, div, button {
                    // 清除Button樣式
                    margin: 0;
                    padding: 0;
                    border: unset;
                    outline: none;

                    // 清除a href樣式
                    text-decoration: none;
                    color: #000;

                    flex: 1;
                    padding-bottom: calc( 100% / 3 );
                    position: relative;
                    background-repeat: no-repeat;

                    &:first-child {
                        background-image: url("/assets/icons/indexButtons/desktopA/icon05.svg");
                        @media (max-width: 640px) {
                            background-image: url("/assets/icons/indexButtons/mobileA/icon05.svg");  
                        }
                    }
                    &:nth-child(2) {
                        background-image: url("/assets/icons/indexButtons/desktopA/icon06.svg");
                        @media (max-width: 640px) {
                            background-image: url("/assets/icons/indexButtons/mobileA/icon06.svg");  
                        }
                    }
                    &:nth-child(3) {
                        background-image: url("/assets/icons/indexButtons/desktopA/icon07.svg");
                        @media (max-width: 640px) {
                            background-image: url("/assets/icons/indexButtons/mobileA/icon07.svg");  
                        }
                    }
                }
            }
        }

        .buttonsContentA__btns.android {
            .buttonsContentA__btns__btnTop {
                width: calc( 100% / 7 * 3 );
                @media (max-width: 640px) {
                    width: calc( 100vw / 3 * 3);
                }
            }
        }
    }

    .buttonsContentB{
        width: 100vw;
        height: 200px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 1280px){
            max-width: 960px;
        }
        @media (min-width: 641px) and (max-width: 1279px){
            max-width: 768px;
            height: 160px;
        }
        @media (max-width: 640px) {
            position: fixed;
            bottom: 0;
            max-width: 480px;
            height: 90px;
        }
        &__btns {
            flex: 1;
            text-align: center;
            display: flex;
            align-items: center; 
            justify-content: center;

            &__btnTop {
                // flex: 1;
                width: calc( 100% / 7 * 4 );
                text-align: center;
                display: flex;

                @media (max-width: 640px) {
                    position: fixed;
                    width: calc( 100vw / 4 * 4);
                    top: 67px;
                }

                a, div, button {
                    // 清除Button樣式
                    margin: 0;
                    padding: 0;
                    border: unset;
                    outline: none;

                    // 清除a href樣式
                    text-decoration: none;
                    color: #000;

                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-position: center;
                    padding-bottom: calc( 100% / 4 );
                    position: relative;
                    background-repeat: no-repeat;
                    background-color: unset;

                    &:first-child {
                        background-image: url("/assets/icons/indexButtons/desktopB/icon01.svg");
                        @media (max-width: 640px) {
                            background-image: url("/assets/icons/indexButtons/mobileB/icon01.svg");  
                        }
                    }
                    &:first-child.hidden {
                        display: none;
                    }
                    &:nth-child(2) {
                        background-image: url("/assets/icons/indexButtons/desktopB/icon02.svg");
                        @media (max-width: 640px) {
                            background-image: url("/assets/icons/indexButtons/mobileB/icon02.svg");  
                        }
                    }
                    &:nth-child(3) {
                        background-image: url("/assets/icons/indexButtons/desktopB/icon03.svg");
                        @media (max-width: 640px) {
                            background-image: url("/assets/icons/indexButtons/mobileB/icon03.svg");  
                        }
                    }
                    &:nth-child(4) {
                        background-image: url("/assets/icons/indexButtons/desktopB/icon04.svg");
                        @media (max-width: 640px) {
                            background-image: url("/assets/icons/indexButtons/mobileB/icon04.svg");  
                        }
                    }
                }
            }

            &__btnBottom {
                // flex: 1;
                width: calc( 100% / 7 * 3 );
                text-align: center;
                display: flex;

                @media (max-width: 640px) {
                    position: absolute;
                    bottom: 0px;
                    width: calc( 100vw / 4 * 3);
                }

                a, div, button {
                    // 清除Button樣式
                    margin: 0;
                    padding: 0;
                    border: unset;
                    outline: none;    

                    // 清除a href樣式
                    text-decoration: none;
                    color: #000;  

                    flex: 1; 
                    padding-bottom: calc( 100% / 3 );
                    position: relative;
                    background-repeat: no-repeat;

                    &:first-child {
                        background-image: ${ Config.isBonnyStar ?  
                            'url("/assets/icons/indexButtons/desktopB/bonny_star.svg")'
                            :
                            'url("/assets/icons/indexButtons/desktopB/icon05.svg")'
                        };
                        @media (max-width: 640px) {
                            background-image: ${ Config.isBonnyStar ?  
                                'url("/assets/icons/indexButtons/mobileB/bonny_star.svg")'
                                :
                                'url("/assets/icons/indexButtons/mobileB/icon05.svg")'
                            };
                        }
                    }
                    &:nth-child(2) {
                        background-image: url("/assets/icons/indexButtons/desktopB/icon06.svg");
                        @media (max-width: 640px) {
                            background-image: url("/assets/icons/indexButtons/mobileB/icon06.svg");  
                        }
                    }
                    &:nth-child(3) {
                        background-image: url("/assets/icons/indexButtons/desktopB/icon07.svg");
                        @media (max-width: 640px) {
                            background-image: url("/assets/icons/indexButtons/mobileB/icon07.svg");  
                        }
                    }
                }
            }
        }
        .buttonsContentB__btns.android {
            .buttonsContentB__btns__btnTop {
                width: calc( 100% / 7 * 3 );
                @media (max-width: 640px) {
                    width: calc( 100vw / 3 * 3);
                }
            }
        }
    }
`;